@mixin avatar-bg {
    $colors: $bg-purple,
        $bg-green,
        $bg-pink,
        $bg-blue,
        $bg-yellow,
        $bg-orange,
        $bg-red;

    @for $i from 1 through length($colors) {
        $c: nth($colors, $i);
        $idx: $i - 1;

        &.bg-#{$idx} {
            background-color: $c;
        }
    }

    &.bg-m {
        background-color: $bg-m;
    }
}