.app-sidebar {
  width: $app-sidebar-width !important;
  background-color: $app-sidebar !important;
  border-right: 1px $border-light solid !important;
  .header {
    height: 88px;
  }
}

.app-sidebar-wrapper,
.app-sidebar-footer {
  .menu-item {
    height: $menu-item-heigh;
    &.active {
      background-color: $menu-item-active;
      .menu-title {
        color: $primary !important;
      }
    }
    border-radius: 12px;
    .menu-link {
      padding: 8px 12px;
      .menu-title {
        color: $text-secondary;
        font-weight: 700 !important;
        font-size: 14px !important;
      }
    }
  }
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: $white;
    font-weight: bold;
    background-color: $bg-orange;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}

.view-select {
  padding: 8px;
  margin-bottom: 10px;
  .btn {
    border-radius: 12px !important;
  }
}

.popup-content {
  .menu-title {
    color: $text-secondary;
    font-weight: 700 !important;
    font-size: 14px !important;
  }
}
