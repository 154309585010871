.header {
  height: 88px;
  padding: 24px 32px;

  .title {
    color: $text-primary;
  }
}

.btn-switch {
  background-color: #f7f7f8;
  border-radius: 32px;

  .button {
    background-color: #f7f7f8;
    border-radius: 12px;
    padding: 0 12px;
    height: 38px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;

    &.inactive {
      color: $text-secondary;
    }

    &.active {
      background-color: $primary;
      color: white;
    }
  }
}

.pie-chart {
  flex: 1;

  .title {
    font-size: 24px;
    font-weight: 700;
  }

  div {
    .info {
      margin-top: 24px;

      div {
        flex: 1;

        .label {
          color: $text-secondary;
          font-size: 12px;
          font-weight: 700;
        }
      }

      .left {
        color: $text-accent-green;

        .diff {
          background-color: $bg-accent-green;
        }
      }

      .right {
        color: $text-accent-pink;

        .diff {
          background-color: $bg-accent-pink;
        }
      }

      .value {
        font-size: 20px;
        font-weight: 700;
      }

      .diff {
        padding: 2px 8px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}

.btn-gray {
  background-color: $bg-accent-gray;
  border-radius: 12px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 700;
  color: $text-body;
  border: none;
}

.met-with-most {
  .list {
    .item {
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: $white;
        font-weight: bold;
      }

      .content {
        div {
          flex: 1;
        }
      }
    }
  }
}

.subscription-modal {
  .inner-wrapper {
    max-width: 794px !important;
  }

  .subscription-content {
    display: flex;
    padding: 32px;
    width: 794px;

    .current-plan {
      flex: auto;
      padding-right: 24px;

      .button-close {
        width: 32px;
        height: 32px;
        background-color: $bg-accent-gray;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .current-plan-box {
        border: 1px solid $border-light;
        border-radius: 16px;
        padding: 40px;

        .current-plan-badge {
          width: fit-content;
          background-color: $bg-accent-gray;
          padding: 4px 8px;
          border-radius: 32px;
          font-size: 14px;
          font-weight: 700;
          color: $text-accent-gray;
        }
      }
    }

    .paid-plan {
      width: 320px;
      background: linear-gradient(356.88deg, $blue 32.1%, $primary 103.54%);
      border-radius: 16px;
      padding: 80px 40px;

      .add-button {
        width: 100%;
        padding: 12px 20px;
        background-color: $primary;
        color: $white;
        border: none;
        border-radius: 12px;
        font-size: 18px;
        font-weight: 700;

        &:hover {
          background-color: $primary-text;
        }
      }
    }
  }
}

.update-user-seats-modal {
  .inner-wrapper {
    max-width: 396px !important;
  }

  .pop-content {
    .circle-button {
      cursor: pointer;

      &:hover {
        color: $blue;
      }
    }
  }
}