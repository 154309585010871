.setting-page {
  .tab-header {
    display: flex;

    .tab-item {
      color: $text-secondary;
      font-size: 14px;
      font-weight: 700;
      padding: 7px 12px;
      cursor: pointer;

      &.active {
        color: $primary;
        background-color: $bg-accent-blue;
        border-radius: 32px;
      }
    }
  }

  .tab-content {
    margin-top: 16px;

    .team-profile {
      .department-wrap {
        max-width: 480px;
        border: 1px $border-accent-gray solid;
        border-radius: 8px;
        padding: 16px;
      }

      .text-input-wrap {
        max-width: 480px;
        padding-bottom: 16px;

        label {
          display: block;
          font-size: 14px;
          font-weight: 700;
          color: $text-body;
          margin-bottom: 8px;
        }

        .input {
          width: 100%;
          border-radius: 8px;
          border: 1px $border-accent-gray solid;
          padding: 12px 8px;
        }

        .input-wrap {
          display: flex;
          align-items: center;
          width: 100%;
          border-radius: 8px;
          border: 1px $border-accent-gray solid;
          padding: 0px 8px;

          label {
            vertical-align: middle;
            margin: 0 8px 0 0;
          }
          input {
            flex: 1;
            border: none;
            height: 36px;
          }
        }

        .form-switch {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin: 0;
          padding: 0;
        }

        .timezone-select {
          height: 46px;
        }
      }
    }

    .billing {
      .billing-detail {
        display: flex;
        justify-content: space-around;

        .billing-detail-item {
          flex: 1;

          .title {
            font-size: 14px;
            font-weight: 700;
            color: $text-secondary;
          }

          .value {
            font-size: 24px;
            font-weight: 700;
          }
        }
      }

      .billing-history {
        display: flex;
        width: 100%;

        .billing-history-table {
          width: 64%;
          padding-top: 24px;
          padding-right: 32px;

          .title-date {
            font-size: 18px;
            font-weight: 700;
            color: $text-body !important;
          }
        }

        .payment-detail {
          width: 36%;
          padding: 24px;

          .payment-detail-title {
            font-size: 24px;
            font-weight: 700;
            color: $text-body;
          }

          .payment-detail-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px $border-light solid;
            border-radius: 16px;
            padding: 16px;
            margin-top: 16px;

            .payment-info {
              flex: 1;
              margin-left: 16px;

              .sub-info {
                display: flex;
                align-items: center;

                span {
                  font-size: 12px;
                  font-weight: 700;
                  color: $text-accent-gray;
                }
              }
            }
          }
        }
      }
    }
  }
}
