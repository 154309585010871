.onboarding-modal {
  border-radius: 24px !important;

  .onboarding-header {
    height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn-wrap {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 12px;

      .btn-close {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }

    .title-wrap {
      padding-left: 24px;
      padding-right: 24px;

      .onboarding-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        color: $text-body;
      }

      .onboarding-subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $text-secondary;
      }
    }
  }

  .onboarding-content-wrap {
    height: 300px;
    padding: 24px;

    .onboarding-content {
      width: 100%;
      height: 100%;
      padding: 8px;
      overflow-y: auto;

      &.compensation-wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .compensation-info {
          display: flex;
          align-items: center;

          .compensation-currency {
            font-size: 60px;
            line-height: 60px;
            font-weight: 700;
          }

          .compensation-value {
            font-size: 96px;
            line-height: 96px;
            font-weight: 700;
            border: none;
          }
        }

        .compensation-per-hour {
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
        }
      }

      &.border-wrap {
        border: 1px $border-accent-gray solid;
        border-radius: 8px;
      }

      &.department-list-wrap {
        .tag-list-item {
          cursor: pointer;
        }
      }

      &.timezone-list {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .timezone-list-item {
          cursor: pointer;
          padding: 4px 8px;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          color: $text-secondary;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.active {
            color: $primary;
            background-color: $bg-accent-gray;
          }
        }
      }
    }
  }

  .onboarding-footer {
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;

    .footer-button {
      border-radius: 12px;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      border: none;
      padding: 8px 12px;

      &.back {
        background-color: $bg-accent-gray;
        color: $text-body;
      }

      &.skip {
        margin-left: auto;
        margin-right: 8px;
      }

      &.next {
        background-color: $primary;
        color: $white;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &:disabled {
        background-color: $bg-disabled;
        color: $text-disabled;
      }
    }
  }
}