.user-details {
  display: flex;

  .left-container {
    width: 440px;
    padding: 24px;

    .user-content {
      padding: 24px;
      border: 1px solid $border-light;
      border-radius: 24px;

      .user-info {
        .basic-info {
          display: flex;
          align-items: center;
          flex-direction: column;
          font-size: 30px;
          font-weight: 700;

          .user-avatar {
            width: 110px;
            height: 110px;
            border-radius: 100%;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;

            &.no-image {
              background-color: $bg-blue;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .user-name {
            color: $text-body;
            margin-top: 16px;
          }
        }

        .profile-info {
          display: flex;
          flex-flow: row wrap;
          gap: 16px;

          .info-wrap {
            flex: 1 0 auto;
            padding: 12px;
            background-color: $bg-accent-gray;
            border-radius: 12px;

            .info-title {
              font-size: 12px;
              font-weight: 700;
              color: $text-secondary;
            }

            .info-value {
              font-size: 20px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .right-container {
    width: calc(100% - 440px);
    padding: 32px;

    .user-recent-meetings {
      .title-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          font-size: 24px;
          font-weight: 700;
          color: $text-body;
        }

        .button {
          padding: 4px 8px;
          border: none;
          border-radius: 8px;
          color: $text-body;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
}

.user-update-modal {
  .input-wrap {
    width: 100%;

    input {
      width: 100%;
      height: 38px;
      border: 1px solid $border-accent-gray;
      border-radius: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .compensation-wrap {
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;

    .compensation-info {
      display: flex;
      align-items: center;

      .compensation-currency {
        font-size: 20px;
        font-weight: 700;
      }

      .compensation-value {
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        border: none;
      }
    }
  }

  .timezone-list {
    height: 100px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .timezone-list-item {
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      color: $text-secondary;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.active {
        color: $primary;
        background-color: $bg-accent-gray;
      }
    }
  }
}