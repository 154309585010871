.pop-wrapper {
  --bs-border-color: #ebf1f4;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000094;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .inner-wrapper {
    background-color: #fff;
    max-width: 650px;
    width: 100%;
    border-radius: 16px;
    .head {
      position: relative;
      .cross-btn {
        position: absolute;
        right: 30px;
        top: 0;
        bottom: 0;
        margin: auto;
        max-height: 12px;
        cursor: pointer;
      }
    }
  }
}

.pop-modal {
  position: fixed;
  width: 440px;
  height: 100%;
  top: 0;
  right: -440px;
  background-color: #fff;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
  z-index: 205;

  &.show {
    right: 0;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.pop-modal-overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 200;

  &.show {
    display: block;
  }
}