$primary: #216df4;
$primary-text: #1e61da;
$secondary: #6c6c89;

$accent-gray-bolder: #f7f7f8;
$accent-red: #fef0f4;
$accent-yellow: #fff9eb;

$blue: #1815b7;
$clay: #cddbfe;
$white: #ffffff;
$green: #259e5a;

$bg-purple: #7047eb;
$bg-green: #2dca72;
$bg-pink: #f75fde;
$bg-blue: #00acff;
$bg-yellow: #ffc233;
$bg-orange: #ff7d52;
$bg-red: #f53d6b;
$bg-m: #55556d;
$bg-disabled: #d1d1db;

$bg-accent-green: #eefbf4;
$bg-accent-pink: #feecfb;
$bg-accent-blue: #f0faff;
$bg-accent-gray: #f7f7f8;

$border-light: #ebebef;
$border-accent-blue: #ade4ff;
$border-accent-gray: #d1d1db;

$text-body: #121217;
$text-accent-gray: #3f3f50;
$text-accent-blue: #0075ad;
$text-accent-green: #17663a;
$text-accent-pink: #a5088c;
$text-accent-red: #d50b3e;
$text-disabled: #a9a9bc;
$text-primary: #121217;
$text-secondary: #6c6c89;
$text-required: #f53d6b;

$icon-status-red: #f53d6b;
$icon-status-green: #2dca72;
$icon-status-yellow: #ffc233;
$icon-status-grey: #d1d1db;

$app-sidebar: #f7f7f8;
$menu-item-active: #eeeef3;

$doughnut-gradient-green1: #259e5a;
$doughnut-gradient-green2: #2dca72;
$doughnut-gradient-pink1: #c728ad;
$doughnut-gradient-pink2: #f75fde;

$app-sidebar-width: 276px;
$menu-item-heigh: 40px;
$dashboard-left-box: 63%;
$dashboard-right-box: 47%;

$theme-color: (
  primary: $primary,
  secondary: $secondary,
);
