.meeting-detail {
  height: 100vh;

  .meeting-detail-header {
    height: 95px;
    padding: 18px 32px;
    border-bottom: 1px solid $border-light;

    .title {
      font-size: 20px;
      font-weight: 700;
    }

    .meeting-info {
      padding-top: 4px;

      div {
        padding: 2px 4px;
        background-color: $bg-accent-gray;
        border-radius: 4px;

        span {
          color: $text-accent-gray;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }

  .meeting-detail-content {
    height: calc(100% - 95px);
    overflow-y: auto;

    .box-container {
      width: 100%;
      padding: 18px 32px;

      .box {
        flex: 1;
        background-color: $bg-accent-gray;
        padding: 12px;
        border-radius: 12px;

        .title {
          font-size: 12px;
          font-weight: 700;
          color: $text-secondary;
        }

        .value {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }

    .container-block {
      padding: 20px 24px;

      .container-block-header {
        .title {
          font-size: 20px;
          font-weight: 700;
          color: $text-body;
        }

        .select {
          padding: 0px 12px;
          border-radius: 32px;
          background-color: $bg-accent-gray;
          color: $text-body;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .departments-chart {
        .label {
          color: $text-body;
          font-size: 14px;
          font-weight: 700;

          .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $bg-pink;

            &.bg-0 {
              background-color: $bg-green;
            }

            &.bg-1 {
              background-color: $bg-pink;
            }

            &.bg-2 {
              background-color: $bg-purple;
            }

            &.bg-3 {
              background-color: $bg-blue;
            }

            &.bg-4 {
              background-color: $bg-yellow;
            }

            &.bg-5 {
              background-color: $bg-orange;
            }

            &.bg-6 {
              background-color: $bg-red;
            }

            &.bg-o {
              background-color: $icon-status-grey;
            }
          }
        }

        .value {
          color: $text-accent-blue;
          font-size: 12px;
          font-weight: 700;

          &.value-cost {
            color: $text-accent-red;
          }
        }
      }

      .participants-chart {
        .user-info {
          .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: white;
            background-color: $bg-purple;
            font-size: 16px;
            font-weight: 700;

            @include avatar-bg;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .name {
            max-width: 130px;
            font-size: 14px;
            font-weight: 700;
            color: $text-body;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .department {
            font-size: 12px;
            font-weight: 400;
            color: $text-disabled;
          }
        }

        .value {
          font-size: 12px;
          font-weight: 700;
          color: $text-accent-red;
        }
      }

      .form-switch {
        .form-check-input {
          width: 30px;
          height: 16px;
        }

        .form-switch-label {
          font-size: 14px;

          .title {
            font-weight: 700;
            color: $text-body;
          }

          .description {
            color: $text-secondary;
          }
        }
      }
    }
  }
}