.metrics {
  .metrics-info {
    width: 340px;
    min-width: 340px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .item {
      border-radius: 16px;
      border: 1px $border-light solid;
      padding: 16px 0 16px 24px;
      .label {
        font-size: 14px;
        font-weight: 700;
        color: $text-secondary;
      }

      .value {
        font-size: 30px;
        font-weight: 700;
      }
    }
  }
}
