.goals {
  .divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $border-light;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .goal-modal {
    .wrapper {
      max-width: 1064px;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      color: $text-body;
    }

    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: $text-secondary;
    }

    .label {
      font-size: 20px;
      font-weight: 700;
      color: $text-body;
    }

    .button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 8px 12px;
      border-radius: 12px;
      border: 1px $border-accent-gray solid;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      color: $text-body;

      &.active {
        background-color: $primary;
        color: white;
      }
    }

    .input-wrap {
      display: flex;
      flex-wrap: wrap;
      min-height: 120px;
      // align-items: center;

      .input-item {
        margin-top: 16px;
        color: $text-body;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}

.goal-list {
  .button:not(.btn-switch .button) {
    cursor: pointer;
    padding: 7px 16px;
    border-radius: 32px;
    font-size: 14px;
    font-weight: 700;
    color: $text-secondary;

    &.active {
      color: $primary;
      background-color: $bg-accent-blue;
    }
  }

  .btn-switch {
    margin-left: 0 !important;
  }

  .item-header {
    color: $text-secondary;
    font-size: 12px;
    font-weight: 700;

    .success {
      min-width: 280px;
    }
  }

  .item {
    .badge {
      background: $accent-gray-bolder;
      padding: 4px 8px;
      border-radius: 8px;
      border: 1px solid $border-accent-gray;
      color: $text-accent-gray;
      font-size: 14px;
      font-weight: 700;
    }

    &.status-complete {
      background: $accent-red;
    }

    &.status-almost_done {
      background: $accent-yellow;
    }

    &.status-in_progress {
      border: 1px solid $border-light;
    }

    .success {
      min-width: 242px;
      color: $text-secondary;
      font-size: 12px;
      font-weight: 400;

      .circle-status {
        width: 12px;
        height: 12px;
        outline: 2px white solid;
        border-radius: 50%;

        &.complete {
          background-color: $icon-status-red;
        }

        &.almost_done {
          background-color: $icon-status-yellow;
        }

        &.in_progress {
          background-color: $icon-status-green;
        }
      }
    }

    .btn-action {
      cursor: pointer;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      .dropdown-toggle::after {
        display: none;
      }

      .dropdown {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          padding: 0 !important;
        }

        .btn-primary {
          background-color: transparent;
        }
      }
    }
  }
}