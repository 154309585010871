.table-responsive {
  table {
    tr {

      th,
      td {
        padding-left: 5px !important;
        padding-right: 5px !important;
        text-transform: capitalize;
      }
    }
  }

  .lst-img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    overflow: hidden;
    background-color: #ebf1f4;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }

    span {
      color: $white;
    }

    &.bg-0 {
      background-color: $bg-purple;
    }

    &.bg-1 {
      background-color: $bg-green;
    }

    &.bg-2 {
      background-color: $bg-pink;
    }

    &.bg-3 {
      background-color: $bg-blue;
    }

    &.bg-4 {
      background-color: $bg-yellow;
    }

    &.bg-5 {
      background-color: $bg-orange;
    }

    &.bg-6 {
      background-color: $bg-red;
    }
  }

  max-height: 550px;
  overflow: auto;

  &.pagination {
    max-height: inherit;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #fff;
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ebf1f4;
    display: block !important;
  }

  td {
    padding-left: 0;
    padding-right: 0;
  }
}

.data-tables {
  &.metrics {
    .table-responsive {
      border: 0 !important;

      th {
        // border: 0 !important;
        font-size: inherit !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
      }

      td {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
  }

  .search {
    position: relative;
  }

  .inputlabel {
    width: 280px;
    padding: 8px 16px;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px $border-accent-gray solid;
    color: #9293a1;
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #9293a1;
    background-image: url("../../images/search-icon.svg");
    background-repeat: no-repeat;
    background-position: 16px 12px;
    padding-left: 40px;
  }

  .table-responsive {
    thead {
      tr {
        border-bottom: 1px solid $border-accent-gray !important;
      }
    }

    tbody {
      tr {
        cursor: pointer;
        font-weight: 400 !important;

        &:hover {
          // font-weight: 700 !important;
          background-color: $bg-accent-blue;
        }
      }
    }

    th {
      padding: 16px !important;
      font-size: 12px !important;
      font-weight: 700 !important;
      color: $text-accent-gray !important;

      &.sortable {
        background-position: right;
        background-repeat: no-repeat;
        background-size: 19px, 19px;
        cursor: pointer;
      }

      &.both {
        background-image: url("data:image/svg+xml; charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='%23000000' opacity='0.56'%3E%3Cpolygon points='50,10 70,45 30,45'/%3E%3Cpolygon points='30,55 70,55 50,90' /%3E%3C/svg%3E");
      }

      &.desc {
        background-image: url("data:image/svg+xml; charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23000000' opacity='0.69'%3E%3Cpolygon points='30,55 70,55 50,90' /%3E%3C/svg%3E");
      }

      &.asc {
        background-image: url("data:image/svg+xml; charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23000000' opacity='0.69'%3E%3Cpolygon points='50,10 70,45 30,45'/%3E%3C/svg%3E");
      }
    }

    td {
      padding: 16px !important;
      color: $text-secondary !important;
      background-color: transparent;

      &.title {
        font-size: 18px !important;
        font-weight: 700 !important;
        color: $text-body !important;
      }

      &.cost {
        color: $text-required !important;
      }
    }

    .image-grp {
      display: flex;
      align-items: center;
      padding-left: 9px;

      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $bg-purple;
        margin-left: -9px;

        @include avatar-bg;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
          display: block;
        }

        span {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          color: #dbdfe9;
          font-weight: 700;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .pagination {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    display: flex;

    li {
      a {
        text-decoration: none;
        color: #3f4254;
        font-size: 14px;
        min-width: 32px;
        min-height: 32px;
        border-radius: 12px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &.selected {
        a {
          background-color: $primary;
          color: #fff;
        }
      }

      &.next,
      &.previous {
        a {
          font-size: 0;
          background-image: url("../../images/right-icon.svg");
          background-repeat: no-repeat;
          background-position: center;
        }

        &.disabled {
          a {
            pointer-events: none;
            color: #a1a5b7;
            opacity: 0.7;
          }
        }
      }

      &.previous a {
        transform: rotate(180deg);
      }
    }
  }
}

.details-icons-content {
  span {
    font-size: 12px;
    line-height: normal;
    color: #3f4254;
  }
}

.user-grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;

  &.admin-grid {
    .admin-metrics-inner {
      width: calc(50% - 12px);
    }
  }

  .user-grid-inner {
    width: calc(50% - 12px);
    border-radius: 16px;
    border: 2px solid #cddbfe;
    padding: 24px;
    text-decoration: none;
    overflow: hidden;

    img {
      width: 64px;
      height: 64px;
      border-radius: 100%;
    }

    h6 {
      color: #3f4254;
    }

    p {
      color: #9293a1;
      font-weight: 500;
    }
  }

  .admin-metrics-inner {
    width: calc(33.33% - 16px);
    border-radius: 16px;
    min-height: 135px;

    h5 {
      color: #3f4254;
      text-align: center;
      min-height: 40px;
    }

    h2 {
      color: #9293a1;
      text-align: center;
      line-height: 1;
    }
  }
}

.table:not(.table-bordered).table-row-dashed tr {
  border: 0;
}

.user-detials-charts {
  .pie-chart-container {
    max-width: 220px;
    max-height: 180px;
    margin: auto;
  }
}

.maintain-ratio {
  .pie-chart-container {
    max-width: 350px;
    max-height: 250px;
    margin: auto;
  }
}

.modal-user {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-user-content {
    max-width: 650px;
    width: 100%;
    margin: auto;
    border-radius: 16px;
    border-bottom: 1px solid #ebf1f4;
    background-color: #fff;

    .header-div {
      padding: 23px 33px;
      border-bottom: 1px solid #ebf1f4;
    }

    form {
      padding: 20px 0 40px;

      .form-control {
        padding: 0 55px;
      }

      .btn-grp {
        border-top: 1px solid #ebf1f4;
      }
    }
  }
}

.user-list-select {
  width: fit-content;
  min-width: 96px;
  background-color: $bg-accent-gray;
  padding: 0px 8px;
  border-radius: 12px;

  .circle-status {
    width: 12px;
    height: 12px;
    border-radius: 50%;

    &.active {
      background-color: $icon-status-green;
    }

    &.inactive {
      background-color: $icon-status-grey;
    }
  }

  &.disable-btn {
    opacity: 0.7;
    pointer-events: none;
  }
}

.btn-reinvite {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
}

.btn-add-new-user {
  background-color: #6c96fe;
  border-radius: 16px;
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  line-height: 24px;
  border: 0;
}

.user-select-none {
  pointer-events: none;
}

.dashboard {
  .app-content {
    .left-box {
      width: $dashboard-left-box;
      padding-right: 24px;
    }

    .right-box {
      width: $dashboard-right-box;
      padding-left: 24px;
    }
  }
}

.times-in-meetings {
  .left {
    padding: 16px 0px 16px 24px;
  }

  .right {
    padding: 16px 24px 16px 0px;
  }

  div {
    flex: 1;

    .title {
      color: $text-secondary;
      font-size: 14px;
      font-weight: 700;
    }

    .value {
      font-size: 36px;
      font-weight: 700;
    }
  }
}