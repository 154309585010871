@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;900&family=Mulish:wght@400;500;600;700;800&family=Source+Sans+3:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Mulish", sans-serif !important;
  font-size: 14px;
  color: $text-body;
  font-weight: 400;
}
a {
  color: $primary;
  text-decoration: none;
}

.bg-purple {
  background-color: $bg-purple;
}

.bg-green {
  background-color: $bg-green;
}

.bg-pink {
  background-color: $bg-pink;
}

.bg-blue {
  background-color: $bg-blue;
}

.bg-yellow {
  background-color: $bg-yellow;
}

.text-accent-blue {
  color: $text-accent-blue;
}

.text-accent-red {
  color: $text-accent-red;
}

.text-cl-grey {
  color: #3f4254 !important;
}

.text-blue {
  color: #6c96fe;
}

.text-red {
  color: $text-accent-red;
}

.text-red-i {
  color: $text-accent-red !important;
}

.text-disabled {
  color: $text-disabled;
}

.text-secondary {
  color: $text-secondary;
}

.text-header {
  color: $text-primary;
}

.tc-body {
  color: $text-body !important;
}

.tc-secondary {
  color: $secondary;
}

.tc-white {
  color: white;
}

.text-blue-gradient {
  background: linear-gradient(356.88deg, $blue 32.1%, $primary 103.54%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-h-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-align-center {
  text-align: center;
}

.scroll-auto {
  overflow: auto;
}

.border-bottom {
  border-bottom: 1px $border-light solid;
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

.loader-btn {
  //   width: 85px;
  //   height: 40px;
  //   text-align: center;
  //   display: flex;
  //   justify-content: center;
  pointer-events: none;
  //   background-color: #cddbfe !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.w-20p {
  width: 20px;
}

.w-234_i {
  width: 234px !important;
}

.w-300_i {
  width: 300px !important;
}

.min-w-56 {
  min-width: 56px;
}

.min-w-100 {
  min-width: 100px;
}

.max-w-136 {
  max-width: 136px;
}

.min-w-140 {
  min-width: 140px;
}

.min-w-156 {
  min-width: 156px;
}

.min-w-234 {
  min-width: 234px;
}

.rounded-3 {
  border-radius: 16px !important;
}
.mr-12 {
  margin-right: -12px;
}
.mr12 {
  margin-right: 12px;
}
.pl-20 {
  padding-left: 20px;
}

.p-6 {
  padding: 24px !important;
}

.font-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-24 {
  font-size: 24px;
}
.fs-30 {
  font-size: 30px;
}
.fs-32 {
  font-size: 32px;
}
.fs-48 {
  font-size: 48px;
}
.fs-96 {
  font-size: 96px;
}

.fw-700 {
  font-weight: 700;
}

.lh-56 {
  line-height: 56px;
}

.p-24 {
  padding: 24px;
}
.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.w-max-content {
  max-width: max-content !important;
}

.btn-primary {
  height: 40px;
  background: $primary;
  border-radius: 12px;
  padding: 8px 12px;
  color: $white;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  border: 0;

  &:hover {
    background: $primary-text;
  }
  &:disabled {
    background: $bg-disabled;
  }
}
.primary-button {
  background-color: rgb(108, 150, 254) !important;
  color: $white !important;
  border-radius: 16px !important;
}
.danger-button {
  background-color: #f1416c !important;
  color: $white !important;
  border-radius: 16px !important;
}
.white-button {
  background-color: $white !important;
  color: #3f4254 !important;
  border-radius: 16px !important;
}
.grey-button {
  background-color: #ebf1f4 !important;
  color: #3f4254 !important;
  border-radius: 16px !important;
}
.clay-button {
  background-color: $clay !important;
  color: #3f4254 !important;
}

.bg-white-imp {
  background-color: #fff !important;
}

.max-width-select-btn {
  .basic-select {
    max-width: max-content;
    width: 150px;
  }
}
.send-invite {
  .basic-select {
    min-height: 100px;
  }
}
.select-style {
  height: 40px;
  color: #3f4254;
  font-size: 14px;
  font-weight: 700;
}

.app-wrapper {
  margin-left: $app-sidebar-width !important;
}

.content-wrapper {
  max-width: calc(100vw - 265px);
  padding: 24px 32px !important;
  box-sizing: border-box;
  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
}

.vh-min-100 {
  min-height: 100vh;
}
.file-upload {
  position: relative;
  input {
    position: absolute;
    z-index: 2;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    cursor: pointer;
  }
  button {
    position: relative;
    z-index: 1;
  }
  .uploaded-name {
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
    outline: 0 !important;
    padding-right: 40px !important;
    color: #9293a1;
    .cross-btn {
      right: 14px;
      z-index: 99;
    }
  }
}
.input-wrapper {
  input {
    padding: 10px;
    &:focus {
      outline: none;
    }
  }
}
.currency-wrap {
  max-width: 74px;
}
.basic-select {
  border-radius: 0.5rem;
  .select__control {
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 2px 0 2px 0px;
    .select__indicator-separator {
      display: none;
    }
    .select__dropdown-indicator {
      svg {
        path {
          fill: #9293a1;
        }
      }
    }
    .select__multi-value {
      background-color: #fff;
      font-weight: 700;
      color: #3f4254;
      font-size: 12px;
      border-radius: 30px;
      padding: 0 8px;
      line-height: 18px;
    }
    .select__single-value {
      font-weight: 700;
      color: $text-body;
      font-size: 12px;
    }
  }
}
.step-lst {
  padding: 0;
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li {
    position: relative;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 200px;
    span {
      background-color: $clay;
      display: flex;
      width: 32px;
      min-width: 32px;
      min-height: 32px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}

.slider-wrapper {
  padding-bottom: 0px;
  // padding-bottom: 76px;
}
.custom-arrow {
  display: none;
  position: absolute;
  top: calc(100% - 50px);
  background-color: #ebf1f4;
  border: none;
  color: black;
  cursor: pointer;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  outline: 0;
  &.prev {
    left: -70px; /* Adjust the position of the previous arrow */
    right: 0;
    margin: auto;
  }

  &.next {
    left: 0px; /* Adjust the position of the next arrow */
    right: -70px;
    margin: auto;
  }
  &:hover {
    background-color: #6c96fe;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
}
.carousel-root {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 30px;
  .carousel .thumbs-wrapper {
    margin-bottom: 0;
  }
}
.items-slider {
  height: 100%;
  padding: 0 20px;
  img {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
    border-radius: 16px;
    border: 2px solid #6c96fe !important;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
  input:checked + .slider {
    background-color: #6c96fe;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #6c96fe;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

.plan-detail {
  ul {
    list-style: none;
    padding: 0;
    li {
      @extend .text-cl-grey;
      display: flex;
      gap: 10px;
      margin-top: 16px;
      font-weight: 500;
      font-size: 16px;
      align-items: center;
    }
  }
}

.plan-box {
  padding: 0 12px;
  > div {
    box-shadow: 0px 4px 6px -2px #10182808;
    box-shadow: 0px 12px 16px -4px #10182814;
    min-height: 530px;
  }
  &:last-child {
    > div {
      @extend .bg-blue;
      .plan-detail {
        ul {
          li {
            svg {
              path {
                stroke: #fff;
              }
            }
            span {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.half-blue {
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    background-color: #cddbfe;
    bottom: 0;
    height: 50%;
    left: 0;
    right: 0;
  }
}
.multi-wrapper {
  .multi-list {
    input {
      padding: 0 8px;
    }
    // @extend .bg-blue-lt;
    padding: 12px 8px;
    min-height: 82px;
    border-radius: 12px;
  }
}

/* The container-checkbox */
.container-checkbox {
  display: flex;
  position: relative;
  padding-right: 35px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  color: #9293a1;
  align-items: center;
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark:after {
      display: block;
    }
    &:checked ~ .checkmark {
      background-color: #2196f3;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 23px;
    width: 23px;
    background-color: #ebf1f4;
    margin: auto;
    border-radius: 100%;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.email-list {
  height: calc(100vh - 450px);
  overflow: auto;
  margin-right: 0px;
  padding-right: 0px;
  &.manage-height {
    height: calc(100vh - 570px);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #fff;
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ebf1f4;
    display: block !important;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      padding: 10px 0;
      margin-top: 8px;
      border-bottom: 1px dashed #ebf1f4;
      .lst-pro-img {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.google-btn {
  border-radius: 16px;
  border: 1px solid #dadce0;
  width: 270px;
  height: 55px;
  align-items: center;
  display: flex;
  justify-content: center;
  span {
    color: #3c4043;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    svg {
      width: 22px;
    }
  }
}
.error-message {
  margin: 0;
  padding: 5px 0;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  color: #dc3545;
}

.minh-full {
  min-height: 100%;
}
.click_here {
  color: #6c96fe;
  border-bottom: 1px solid #6c96fe;
}

.carousel .thumbs {
  text-align: center;
  li {
    border: 1px solid #ebf1f4;
    border-radius: 4px;
    box-shadow: 0 0 4px #ebf1f4;
    &.selected,
    &:hover {
      border: 1px solid #6c96fe;
      box-shadow: 0 0 4px #6c96fe;
    }
    img {
      height: 70px;
      object-fit: cover;
      object-position: center;
    }
  }
}
.vh100 {
  height: 100vh;
}
.value-form {
  form {
    min-height: 90px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .value-data {
      width: 44px;
      height: 44px;
      border-radius: 8px;
      border: 1px solid #6c96fe;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: #6c96fe;
      font-weight: bold;
      line-height: 44px;
      user-select: none;
      &:disabled {
        pointer-events: none;
        border-color: #9293a1;
        color: #9293a1;
      }
    }
    .value-value {
      color: #3f4254;
      text-align: center;
      font-size: 72px;
      font-weight: 600;
      line-height: 90px;
      letter-spacing: -1.44px;
      min-width: 90px;
    }
  }
  label {
    color: #9293a1;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    page-break-inside: 20px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.tag-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  .tag-list-item {
    width: fit-content;
    height: fit-content;
    background-color: $bg-accent-blue;
    border: 1px $border-accent-blue solid;
    border-radius: 8px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    color: $text-accent-blue;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;

    .tag-list-item-remove-button {
      cursor: pointer;
    }

    &.active {
      background: linear-gradient(356.88deg, $blue 32.1%, $primary 103.54%);
      color: $white;
    }
  }

  .tag-input {
    border: 1px $border-accent-blue solid;
    border-radius: 8px;
    padding: 4px 8px;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: $text-accent-blue;
  }
}
